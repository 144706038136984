import React from "react"
import { Clock, Watch, Calendar, Tag } from "react-feather"
import { Layout } from "../../components/Layout"
import { SEO } from "../../components/SEO"
import { graphql, Link } from "gatsby"
import styles from "./product.module.css"
import buttons from "../../styles/buttons.module.css"
import helpers from "../../styles/helpers.module.css"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const {
    title,
    excerpt,
    bgHex,
    bgImageMobile,
    bgImageTablet,
    bgImageDesktop,
    productAttributeTitleOne,
    productAttributeTitleTwo,
    productAttributeTitleThree,
    productAttributeTitleFour,
    productAttributeValueOne,
    productAttributeValueTwo,
    productAttributeValueThree,
    productAttributeValueFour,
  } = frontmatter
  return (
    <Layout>
      <SEO title={title} description={excerpt} />
      <div
        style={{ backgroundColor: bgHex }}
        className="bg-gradient-to-r from-lightBlue to-darkBlue"
      >
        <div className="container px-6 pt-12 mx-auto sm:px-8 md:px-12 lg:pt-0 lg:px-6 xl:px-16">
          <div className="grid grid-cols-12 lg:gap-6">
            <div className="flex flex-col items-center justify-center col-span-12 text-center lg:text-left lg:items-start lg:col-span-5">
              <p className="mb-4 font-semibold leading-none tracking-wider text-gray-900 uppercase text-md">
                Treatment
              </p>
              <h1 className="mb-4 leading-none text-gray-900">{title}</h1>
              <p
                className={`${helpers.largegray} text-lg font-medium text-gray-900`}
              >
                {excerpt}
              </p>
              <Link
                className={`${buttons.btnPrimary} block mt-8 md:inline-block`}
                to="/contact"
              >
                Book a consultation
              </Link>
            </div>
            <div className="col-span-12 mt-8 lg:mt-0 lg:col-span-7 lg:flex lg:justify-end">
              <img
                loading="lazy"
                className=""
                alt=""
                src={bgImageMobile}
                srcSet={`${bgImageMobile} 1x, ${bgImageTablet} 1.5x ${bgImageDesktop} 2x`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container px-6 py-8 mx-auto sm:px-8 md:px-12 md:py-16 lg:px-6">
        <div className="grid md:grid-cols-12">
          <div className="md:col-span-12 md:col-start-1 xl:col-span-8 xl:col-start-3">
            <div
              className={`${styles.productContent} mb-6 md:col-span-12 md:col-start-1`}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container px-6 py-16 mx-auto sm:px-8 md:px-12 md:py-24 lg:px-6">
          <div className="grid md:grid-cols-12">
            <div className="px-4 py-16 bg-white border-solid md:col-span-12 md:col-start-1 md:py-24 md:px-32 xl:col-span-8 xl:col-start-3">
              <p className={`${helpers.overline} mb-4 text-center`}>{title}</p>
              <h2 className="pb-6 font-sans text-center md:pb-16 lg:text-5xl">
                At a Glance
              </h2>
              <dl className="border-2">
                <div className="flex justify-between py-6 border-t border-l border-r border-gray-200 border-solid">
                  <dt>
                    <div className="flex">
                      <Clock
                        size="24"
                        className="inline ml-4 mr-2 text-gray-900"
                      />
                      <p className="inline text-lg font-medium text-gray-900">
                        {productAttributeTitleOne}
                      </p>
                    </div>
                  </dt>
                  <dd className="inline mr-4 text-lg font-medium text-right text-gray-600">
                    {productAttributeValueOne}
                  </dd>
                </div>
                <div className="flex justify-between py-6 border-t border-l border-r border-gray-200 border-solid">
                  <dt>
                    <div className="flex items-center">
                      <Watch
                        size="24"
                        className="inline ml-4 mr-2 text-gray-900"
                      />
                      <p className="inline text-lg font-medium text-gray-900">
                        {productAttributeTitleTwo}
                      </p>
                    </div>
                  </dt>
                  <dd className="inline mr-4 text-lg font-medium text-right text-gray-600">
                    {productAttributeValueTwo}
                  </dd>
                </div>
                <div className="flex justify-between py-6 border-t border-l border-r border-gray-200 border-solid">
                  <dt>
                    <div className="flex items-center">
                      <Calendar
                        size="24"
                        className="inline ml-4 mr-2 text-gray-900"
                      />
                      <p className="inline text-lg font-medium text-gray-900">
                        {productAttributeTitleThree}
                      </p>
                    </div>
                  </dt>
                  <dd className="inline mr-4 text-lg font-medium text-right text-gray-600">
                    {productAttributeValueThree}
                  </dd>
                </div>
                <div className="flex justify-between py-6 border-t border-b border-l border-r border-gray-200 border-solid">
                  <dt>
                    <div className="flex items-center">
                      <Tag
                        size="24"
                        className="inline ml-4 mr-2 text-gray-900"
                      />
                      <p className="inline text-lg font-medium text-gray-900">
                        {productAttributeTitleFour}
                      </p>
                    </div>
                  </dt>
                  <dd className="inline mr-4 text-lg font-medium text-right text-gray-600">
                    {productAttributeValueFour}
                  </dd>
                </div>
              </dl>
              <Link
                className={`${buttons.btnPrimary} block mt-8`}
                to="/contact"
              >
                Book your appointment
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        excerpt
        bgHex
        bgImageMobile
        bgImageTablet
        bgImageDesktop
        productAttributeTitleOne
        productAttributeTitleTwo
        productAttributeTitleThree
        productAttributeTitleFour
        productAttributeValueOne
        productAttributeValueTwo
        productAttributeValueThree
        productAttributeValueFour
      }
    }
  }
`
